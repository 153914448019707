import Container from 'components/container'
import Layout from 'components/layout'
import Card from 'components/molecules/card'
import Seo from 'components/molecules/seo'
import ArticleBody from 'components/organisms/articleBody'
import ArticleHeader from 'components/organisms/articleHeader'
import { renderDocumentToReactComponents } from 'components/organisms/renderDocumentToReactComponents'
import Share from 'components/organisms/share'
import { graphql, PageProps } from 'gatsby'
import * as React from 'react'
import { ContentfulPage } from 'types/graphql-types'

type PageTemplateProps = PageProps & {
  data: {
    contentfulPage: ContentfulPage
  }
}

const PageTemplate = (props: PageTemplateProps): JSX.Element => {
  const { contentfulPage } = props.data
  const {
    title,
    heroImage,
    leadingParagraph,
    body,
    metaDescription,
  } = contentfulPage
  const hasImage = heroImage !== null
  return (
    <Layout>
      <Seo
        title={title}
        imageUrl={
          hasImage
            ? heroImage?.fluid.src.replace(/^\/\//, 'https://')
            : undefined
        }
        description={metaDescription?.metaDescription}
        url={props.location.href}
      />
      <ArticleHeader hasImage={hasImage}>
        <Container>
          <Card size="lg" title={title} image={heroImage} hero share />
        </Container>
      </ArticleHeader>

      <ArticleBody hasImage={hasImage}>
        <Container>
          <div className="row">
            <div className="meta page-meta">
              <Share />
            </div>
            <div className="content">
              {!hasImage && (
                <div className="tablet-title">
                  <h1 className="title">{title}</h1>
                </div>
              )}
              {leadingParagraph?.leadingParagraph && (
                <p className="lead">{leadingParagraph?.leadingParagraph}</p>
              )}
              {body && renderDocumentToReactComponents(body)}
            </div>
            <div />
          </div>
        </Container>
      </ArticleBody>
    </Layout>
  )
}

export const query = graphql`
  query ContentFulPage($slug: String, $locale: String) {
    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      node_locale
      heroImage {
        fluid {
          ...Image
        }
      }
      leadingParagraph {
        leadingParagraph
      }
      body {
        raw
        references {
          ... on Node {
            ... on ContentfulImagesContentBlock {
              contentful_id
              internal {
                type
              }
              images {
                fluid {
                  ...Image
                }
              }
            }
            ... on ContentfulAsset {
              contentful_id
              fluid {
                ...Image
              }
            }
            ... on ContentfulVideo {
              contentful_id
              internal {
                type
              }
              url
            }
          }
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`

export default PageTemplate
